.footer {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: #fff;
}

.footer a:hover {
  color: #CF2930;
}

.footer-top {
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 0rem;
  /*border-bottom: 1px solid #CF2930;*/
  width: 100%;
}

.footer-box {
  flex-direction: row;
  padding-top: 0rem;
}

.footer-box h2 {
  color: #616161;
  font-size: 12px;
  margin: 0px;
}

.footer-box h4 {
  text-transform: uppercase;
  letter-spacing: 3.5px;
}

.footer-box p {
  font-size: 12px;
  line-height: 1rem;
  color: #616161;
}

.mail h2, .general h2 {
  font-family: pf_din_text_comp_proregular;
  padding-right: 10px;
}

.footer h4 img {
  padding-right: 10px;
}

.contact {
  font-weight: 500;
  flex-direction: row;
  align-items: center;
}

.contact a {
  font-size: 12px;
}

.footer-nav {
  display: none;
}

.footer-nav ul {
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.footer-nav li {
  text-transform: uppercase;
}

.footer-nav a {
  font-size: 14px;
  margin: 0 5px;
}

.footer-bottom {
  align-items: flex-start;
  flex-flow: column wrap;
}

.footer-bottom p {
  font-size: 14px;
}

.footer-bottom .icon {
  font-size: 14px;
  border-radius: 60px;
  padding: .6em .7em;
  background: rgba(149, 152, 154, 0.3);
}

.footer-bottom .icon:hover {
  background-color: white;
}

.footer-bottom a:not(:first-child) {
  margin-left: 5px;
}

.inquires {
  flex-direction: column;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
  }

  .footer-top {
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    width: auto;
  }

  .footer-box {
    padding-top: 0;
  }

  .footer-box.address {
    align-items: center;
    padding-left: 10px;
  }

  .footer-top {
    /*width: 75%;*/
  }

  .footer-nav li {
    margin: 5px 0;
  }

  .footer-nav a {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-bottom {
    flex-direction: column;
    justify-content: space-between;
    /*width: 75%;*/
  }

  .footer-bottom p {
    font-size: 12px;
  }

  .footer-bottom .icon {
    font-size: 14px;
  }

  .footer-bottom-left {
    padding-top: 21px;
  }
}

@media only screen and (min-width: 922px) {
  .footer {
    padding: 6em 6em;
  }
  .footer-bottom .icon {
    font-size: 18px;
  }
}
