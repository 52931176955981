#roadmap {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

.logo {
  background: url("../../assets/img/square-stripes.svg") no-repeat center;
  background-size: auto;
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  height: 400px;
  background-position-y: 0px;
}

.roadmapItemWrapper {
  display: flex;
  flex-direction: column;
}

.roadmapItem {
  display: flex;
  flex-wrap: wrap;
  background-color: #616161;
  height: 139px;
  width: 373px;
  border-radius: 4px;
  border: 1px solid #fff;
  opacity: 0.2;
}

.roadmapItem:before {
  content: "\25CF";
  font-size: 50px;
  color: #fff;
  position: absolute;
}

.date {
  width: 50%;
  text-align: left;
}
.name {
  width: 50%;
  text-align: right;
  color: #dc4848;
}
.list {
  text-align: left;
}
.list ul {
  list-style-type: circle;
}

.roadmap .description {
  max-width: 28em;
}

.roadmap-img {
  margin: 0 auto;
  content: url("../../assets/img/desktop-all-with-background-new.svg");
  height: 100%;
  width: 100%;
}
/*====================
  Responsive Stylings
=====================*/
@media only screen and (max-width: 768px) {
  .roadmap-img {
    margin: 0 auto;
    content: url("../../assets/img/desktop-all-with-background-new.svg");
    /*background: url("../../assets/img/roadmap-mobile-new.jpg");*/
  }
}
@media only screen and (min-width: 768px) {
  .roadmap-img {
    margin: 0 auto;
    content: url("../../assets/img/desktop-all-with-background-new.svg");
    width: auto;
    /*background: url("../../assets/img/roadmap-mobile-new.jpg");*/
  }
}

.rContainer {
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.rBox {
  width: 80%;
}

.rBox::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  background: linear-gradient(0deg, #fff 77%, #fafafa 23%);
  position: absolute;
  left: 50%;
  top: 150px;
  z-index: 0;
}

.cardBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.cardBox2 {
  width: 50%;
  position: relative;
  left: 6.5%;
}

.cardCurrent {
  width: 70%;
  box-sizing: border-box;
  background: #1a1a20;
  border: 1px solid #1f1f23;
  border-radius: 24px;
  padding: 30px;
  transition: all 2s;
  /* background: linear-gradient(
    93.51deg,
    #6639e4 0%,
    #6c2dbf 45.52%,
    #722099 100%
  ); */
  background-color: #65120c;
}

.cardTitle {
  color: white;
  font-size: 22px;
  margin: 0%;
  padding-bottom: 10px;
}

.list {
  display: flex;
  align-items: center;
  margin-top: 17px;
}

.text {
  margin: 0%;
  color: #fefefe;
  font-size: 17px;
}

.text::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 15px;
  background-color: #b2b2c1;
  border-radius: 50%;
  font-size: 13px;
  color: #b2b2c1;
}

.tracker {
  position: absolute;
  left: -90px;
  top: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.trackerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}

.active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
}

.line {
  width: 50px;
  height: 0.2px;
  background-color: #fff;
}

.cardLeft {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
  padding-left: 4%;
  box-sizing: border-box;
}

.cardLeftBox {
  width: 50%;
  position: relative;
  right: -6.5%;
}

.trackerLeft {
  position: absolute;
  left: 70.5%;
  top: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  transform: rotate(180deg);
  z-index: 1;
}

.trackerLeftBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}

.trackerLeftBox span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
}

@media screen and (max-width: 1000px) {
  .cardBox {
    width: 100%;
    margin: 70px 0px;
  }

  .cardBox2 {
    width: 100%;
    left: 0%;
  }

  .cardLeft {
    width: 100%;
    padding-left: 0%;
    margin: 70px 0px;
  }

  .cardLeftBox {
    width: 100%;
    right: 0%;
  }

  .cardCurrent {
    width: 100%;
  }

  .tracker {
    width: 100%;
    top: -50px;
    left: 47.5%;
  }

  .line {
    display: none;
  }

  .trackerLeft {
    width: 100%;
    top: -50px;
    left: 47.5%;
  }

  .rBox::before{
    left: 50.7%;
  }
}
