.details {
  flex-direction: column;
}

.section-title {
  margin: 0;
}

.highlighted {
  /*color: #CF2930;*/
}
/*====================
  Responsive Stylings
=====================*/