.partner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoBox{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 100px;
    flex-wrap: wrap;
    width: 90%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.logoTextBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logoTextBox p{
    font-size: 20px;
    color: #154273;
}

.logo{
    width: 250px;
    height: 245px;
    transform: rotate(360deg);
}

.logo2{
    width: 220px;
    height: 50px;
}