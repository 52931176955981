.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 1rem;
  transition: background-color 0.5s ease-in;
  z-index: 9999;
  height: 55px;
}

.navbar.active-color {
  background-color: #000;
}

.navbar a {
  font-size: .95rem;
  font-weight: 500;
}

.nav-left {
  flex: 3;
  justify-content: flex-start;
  align-items: center;
}

.navbar-brand {
  font-family: 'Roboto';
  font-size: 1px;
  letter-spacing: 2px;
}

.navbar-brand h1 {
  margin: 0;
  margin-left: 10px;
  font-size: 18px;
}

.nav-right {
  align-items: center;
}

.nav-right a {
  padding-top: 8px;
}

.menu {
  display: none;
}

.menu a {
  margin: 0 10px;
}

.menu a:after {
  content: "";
  display: block;
  padding-top: 5px;
  width: 75%;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.15s ease-in;
}

.menu a:hover:after {
  /*border-bottom: 2px solid #CF2930;*/
}

nav .dropdown-menu {
  display: block;
}

nav .dropdown-btn {
  margin-top: 22px;
  font-size: 22px;
  cursor: pointer;
  padding-right: 1rem;
}

nav .dropdown-content {
  /* display: none; */
  display: flex;
  /*transform: translateX(-75%);*/
  position: absolute;
  background-color: rgba(0,0,0,0.82);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 0rem;
  padding: 5px 0;
  top: 55px;
  left: 0px;
  color: #fff;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
}

nav .dropdown-content a {
  display: block;
  /*max-width: 9em;*/
  margin: 0 auto;
  text-align: left;
  padding: 12px 10px;
  float: none;
  color: #fff;
  border-bottom: 0px solid #F3F1EE;
  width: 100%;
  font-size: 18px;
  font-family: Bebas Neue Regular, Helvetica, sans-serif, system;
}

nav .dropdown-content a:hover {
  /*background-color: #F3F1EE;*/
}

nav .hidden {
  display: none;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 1050px) {
  .nav-logo {
    position: fixed !important;
    z-index: 10000;
    top: 10px;
    left: 10px;
  }
  .nav-logo.active {
    display: none;
  }
  .navbar {
    /*padding: 0 6rem;*/
    /*bottom: 0px;*/
    /*height: auto;*/
    /*align-items: center;*/
    /*width: 285px;*/
    /*flex-direction: row;*/
    height: 100vh;
    right: auto;
    background-color: transparent !important;
    /*filter: blur(31px);*/
    /*opacity: 0.3;*/
  }

  .navbar .nav-left {
    position: absolute;
    display: none;
  }

  .navbar .nav-right {
    display: none;
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*text-align: left;*/
  }

  .navbar img {
    /*width: 50px;*/
  }

  .dropdown {
    display: flex;
    flex-direction: row;
  }

  .menu {
    display: contents;
  }

  nav .dropdown-menu {
    display: flex;
    flex-direction: row;
  }

  .navbar {
    padding: 0;
  }

  nav .dropdown-content {
    position: relative;
    top: 0px;
    justify-content: center;
    box-shadow: none;
    background-color: #ccc;
    opacity: 0.8;
  }

  nav .dropdown-btn {
    align-items: center;
    padding-left: 8px;
    margin-top: 0px;
  }

  .navbar.active .dropdown-btn {
    background-color: #ccc;
    /*filter: blur(31px);*/
    opacity: 0.8;
  }

  .navbar.active img {
    display: none;
  }

  .navbar.active .dropdown-btn {
    margin-top: 0px;
  }

  nav .dropdown-content a {
    color: #fff;
  }
}
