/*================
  Self-Hosted Fonts
===================*/
@font-face {
    font-family: 'Bebas Neue Thin';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Thin.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Light';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Light.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Bold';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Bold.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Book.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Regular';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Regular.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Book.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Light';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Light.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Bold.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Thin.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/bebasneuenew/BebasNeue-Regular.woff2') format('woff2'),
    url('./assets/fonts/bebasneuenew/BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'pf_din_text_comp_proBdIt';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-boldital-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-boldital-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proitalic';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-italic-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_prolight';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proLtIt';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-lightital-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-lightital-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proMdIt';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-medital-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-medital-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_promedium';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proregular';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_prothin';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-thin-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proThIt';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-thinital-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-thinital-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXBlk';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xblack-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xblack-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXBlkIt';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xblackital-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xblackital-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXTh';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xthin-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xthin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXThIt';
    src: url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xthinital-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/pfdintextcomppro-xthinital-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_probold';
    src: url('./assets/fonts/pfdintextcomppro/z-pfdintextcomppro-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/pfdintextcomppro/z-pfdintextcomppro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/* roboto-300 - latin */
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
src: url('./assets/fonts/roboto/roboto-v18-latin-300.eot'); /* IE9 Compat Modes */
src: local('Roboto Light'), local('Roboto-Light'),
     url('./assets/fonts/roboto/roboto-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('./assets/fonts/roboto/roboto-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
     url('./assets/fonts/roboto/roboto-v18-latin-300.woff') format('woff'), /* Modern Browsers */
     url('./assets/fonts/roboto/roboto-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
     url('./assets/fonts/roboto/roboto-v18-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('./assets/fonts/montserrat/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/montserrat/montserrat-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('./assets/fonts/montserrat/montserrat-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat/montserrat-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat/montserrat-v12-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/montserrat/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('./assets/fonts/montserrat/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/montserrat/montserrat-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('./assets/fonts/montserrat/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat/montserrat-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat/montserrat-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat/montserrat-v12-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: "pf_din_text_comp_proregular", Helvetica, sans-serif, system;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: "Futura PT", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stripe {
    background: url('assets/img/black-red-vertical-line.svg') no-repeat;
    height: 85px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -15px;
    margin-right: -15px;
    background-position-x: center;
}

.stripe-white {
    background: url('assets/img/white-red-vertical-line.svg') no-repeat;
    height: 85px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -15px;
    margin-right: -15px;
    background-position-x: center;
}
