/*================
  Self-Hosted Fonts
===================*/
@font-face {
    font-family: 'Bebas Neue Thin';
    src: url(/static/media/BebasNeue-Thin.aab93f86.woff2) format('woff2'),
    url(/static/media/BebasNeue-Thin.37b50cf2.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Light';
    src: url(/static/media/BebasNeue-Light.ed5c0399.woff2) format('woff2'),
    url(/static/media/BebasNeue-Light.0f87d3f1.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Bold';
    src: url(/static/media/BebasNeue-Bold.0cfeb15f.woff2) format('woff2'),
    url(/static/media/BebasNeue-Bold.d6704cb7.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url(/static/media/BebasNeue-Book.bc10ff3e.woff2) format('woff2'),
    url(/static/media/BebasNeue-Book.81729368.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Regular';
    src: url(/static/media/BebasNeue-Regular.cc7a4df2.woff2) format('woff2'),
    url(/static/media/BebasNeue-Regular.619c9933.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url(/static/media/BebasNeue-Book.bc10ff3e.woff2) format('woff2'),
    url(/static/media/BebasNeue-Book.81729368.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue Light';
    src: url(/static/media/BebasNeue-Light.ed5c0399.woff2) format('woff2'),
    url(/static/media/BebasNeue-Light.0f87d3f1.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url(/static/media/BebasNeue-Bold.0cfeb15f.woff2) format('woff2'),
    url(/static/media/BebasNeue-Bold.d6704cb7.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url(/static/media/BebasNeue-Thin.aab93f86.woff2) format('woff2'),
    url(/static/media/BebasNeue-Thin.37b50cf2.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url(/static/media/BebasNeue-Regular.cc7a4df2.woff2) format('woff2'),
    url(/static/media/BebasNeue-Regular.619c9933.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'pf_din_text_comp_proBdIt';
    src: url(/static/media/pfdintextcomppro-boldital-webfont.fd9141bb.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-boldital-webfont.25e4cb26.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proitalic';
    src: url(/static/media/pfdintextcomppro-italic-webfont.8c436293.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-italic-webfont.145b8d25.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_prolight';
    src: url(/static/media/pfdintextcomppro-light-webfont.467c2c13.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-light-webfont.fa979c4c.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proLtIt';
    src: url(/static/media/pfdintextcomppro-lightital-webfont.55a5fe76.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-lightital-webfont.01967d93.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proMdIt';
    src: url(/static/media/pfdintextcomppro-medital-webfont.e7157ef3.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-medital-webfont.003bb534.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_promedium';
    src: url(/static/media/pfdintextcomppro-medium-webfont.3f8ed737.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-medium-webfont.bc9d8e33.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proregular';
    src: url(/static/media/pfdintextcomppro-regular-webfont.bd4e7e6a.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-regular-webfont.8bd9a184.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_prothin';
    src: url(/static/media/pfdintextcomppro-thin-webfont.c57da45a.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-thin-webfont.d5eb43f6.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proThIt';
    src: url(/static/media/pfdintextcomppro-thinital-webfont.99c12c36.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-thinital-webfont.1971c5cf.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXBlk';
    src: url(/static/media/pfdintextcomppro-xblack-webfont.c53cbd9e.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-xblack-webfont.d65810cd.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXBlkIt';
    src: url(/static/media/pfdintextcomppro-xblackital-webfont.2d954e74.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-xblackital-webfont.57429cc5.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXTh';
    src: url(/static/media/pfdintextcomppro-xthin-webfont.c21cf081.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-xthin-webfont.988bb740.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_proXThIt';
    src: url(/static/media/pfdintextcomppro-xthinital-webfont.cd3d0db2.woff2) format('woff2'),
    url(/static/media/pfdintextcomppro-xthinital-webfont.69601bb7.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'pf_din_text_comp_probold';
    src: url(/static/media/z-pfdintextcomppro-bold-webfont.fa4ac12a.woff2) format('woff2'),
    url(/static/media/z-pfdintextcomppro-bold-webfont.a8282889.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
/* roboto-300 - latin */
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
src: url(/static/media/roboto-v18-latin-300.548ebe05.eot); /* IE9 Compat Modes */
src: local('Roboto Light'), local('Roboto-Light'),
     url(/static/media/roboto-v18-latin-300.548ebe05.eot?#iefix) format('embedded-opentype'), 
     url(/static/media/roboto-v18-latin-300.55536c8e.woff2) format('woff2'), 
     url(/static/media/roboto-v18-latin-300.a1471d1d.woff) format('woff'), 
     url(/static/media/roboto-v18-latin-300.ab2789c4.ttf) format('truetype'), 
     url(/static/media/roboto-v18-latin-300.dd0bea1f.svg#Roboto) format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/montserrat-v12-latin-regular.29c1d31f.eot); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url(/static/media/montserrat-v12-latin-regular.29c1d31f.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/montserrat-v12-latin-regular.501ce09c.woff2) format('woff2'), 
       url(/static/media/montserrat-v12-latin-regular.f29d2b85.woff) format('woff'), 
       url(/static/media/montserrat-v12-latin-regular.cdf58259.ttf) format('truetype'), 
       url(/static/media/montserrat-v12-latin-regular.f3fef7e5.svg#Montserrat) format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/montserrat-v12-latin-500.0c1c383c.eot); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url(/static/media/montserrat-v12-latin-500.0c1c383c.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/montserrat-v12-latin-500.f0f2716c.woff2) format('woff2'), 
       url(/static/media/montserrat-v12-latin-500.991b453b.woff) format('woff'), 
       url(/static/media/montserrat-v12-latin-500.d22dabbc.ttf) format('truetype'), 
       url(/static/media/montserrat-v12-latin-500.10c07b65.svg#Montserrat) format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/montserrat-v12-latin-600.2ea6eb7f.eot); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url(/static/media/montserrat-v12-latin-600.2ea6eb7f.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/montserrat-v12-latin-600.15c24f71.woff2) format('woff2'), 
       url(/static/media/montserrat-v12-latin-600.f6dc6096.woff) format('woff'), 
       url(/static/media/montserrat-v12-latin-600.3e161181.ttf) format('truetype'), 
       url(/static/media/montserrat-v12-latin-600.a6924a5e.svg#Montserrat) format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/montserrat-v12-latin-700.040482ce.eot); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url(/static/media/montserrat-v12-latin-700.040482ce.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/montserrat-v12-latin-700.79982cd1.woff2) format('woff2'), 
       url(/static/media/montserrat-v12-latin-700.957e93fb.woff) format('woff'), 
       url(/static/media/montserrat-v12-latin-700.410890af.ttf) format('truetype'), 
       url(/static/media/montserrat-v12-latin-700.f9c15375.svg#Montserrat) format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: "pf_din_text_comp_proregular", Helvetica, sans-serif, system;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: "Futura PT", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stripe {
    background: url(/static/media/black-red-vertical-line.0adf96f3.svg) no-repeat;
    height: 85px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -15px;
    margin-right: -15px;
    background-position-x: center;
}

.stripe-white {
    background: url(/static/media/white-red-vertical-line.915e5c80.svg) no-repeat;
    height: 85px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -15px;
    margin-right: -15px;
    background-position-x: center;
}

/*================
  Global Stylings
===================*/
.App {
  overflow-x: hidden;
}

a {text-decoration: none;color: inherit;}

h1 {
  font-family: Bebas Neue Light, Helvetica, sans-serif, system;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
}

h2 {
  font-family: Bebas Neue Regular, Helvetica, sans-serif, system;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h3 {
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

ul {
  list-style: none;
  padding-left: 1.3rem;
}

p {
  font-size: 1rem;
  line-height: 1.875rem;
}

.show {display: block;}

.hide {display: none;}

.dropdown {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.uppercase {text-transform: uppercase;}

.redline-wrapper {
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 15px;
}

.redline-wrapper span {
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 5px;
}

.has-globe-wallpaper {
  /* background: linear-gradient(0deg,rgba(1, 1, 1, 1),rgba(1, 1, 1, 0)), url('../../assets/img/globe.png') no-repeat; */
  background-size: auto;
  /*height: auto;*/
}

.has-network-wallpaper,
.has-stars-wallpaper {
  background: black;
  color: white;
  border: 2px solid black;
}
/*================
  Button Stylings
===================*/
.btn {
  color: white;
  font-size: 16px;
  border-radius: 50px;
  width: 12rem;
  height: 3.7rem;
  cursor: pointer;
  outline: 0;
  font-family: pf_din_text_comp_promedium;
  letter-spacing: 2px;
}

.btn-primary {
  background-color: #CF2930;
  border: 2px solid #CF2930;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #CF2930;
}

.btn-secondary:hover {
  background-color: #CF2930;
  border: 2px solid #CF2930;
}

.description {
  font-family: pf_din_text_comp_proregular, Helvetica, sans-serif, system;
  letter-spacing: 1px;
  color: #616161;
  font-size: 1rem;
}

/*====================
  Responsive Stylings
=====================*/
.flex {display: -webkit-flex;display: flex;}

@media only screen and (min-width: 520px) {
  .has-globe-wallpaper {
    /*background-attachment: fixed;*/
    background-position: 50%;
  }

  .has-network-wallpaper {
    /*background: linear-gradient(0deg,rgba(1, 1, 1, 0.7),rgba(1, 1, 1, 0)), url('../../assets/img/network.png') no-repeat;*/
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
  }

  .has-stars-wallpaper {
    background: url(/static/media/stars.cc071d1d.png) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 3rem;
  }

  .has-desktop-padding {
    padding: 6em 6em;
  }
}

@media only screen and (max-width: 768px) {
  .btn {
    width: 10rem;
  }

  .has-mobile-padding {
    padding: 3rem 1rem;
  }
}

header {
  color: white;
  padding: 2rem 1rem 6rem 1rem;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: url(/static/media/martian-wallpaper-group.fb21dd7c.svg) no-repeat #000;
  background-position: center 40px;
  background-size: auto;
  height: calc(100vh - 110px);
  min-height: 130vh;
}

.nav-bottom {
  display: none;
}

.header-middle {
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 5rem;
}

.header-middle h1 {
  max-width: 17em;
}

.header-middle p {
  max-width: 40rem;
}

.buttons-wrapper {
  -webkit-align-items: center;
          align-items: center;
  max-width: 40rem;
  margin-top: 40px;
}

.buttons-wrapper a:nth-child(2) {
  margin-left: 1rem;
}

.buttons-wrapper button {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* font-family: 'FuturaPT'; */
}

.coming-soon:hover span {
  -webkit-align-items: center;
          align-items: center;
  display: none;
}

.coming-soon:hover:before{
  content: "Coming Soon";
}

.buttons-wrapper .icon {
  margin-right: 5px;
}

.header-bottom {
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 30em;
}

header .redline-wrapper {
  margin: 5rem 0 1rem 0;
}

.header-bottom a {
  border-radius: 100%;
  padding: 1em 1.2em;
  background: rgba(149, 152, 154, 0.3);
}

.header-bottom a:not(:first-child) {
  margin-left: 5px;
}

.header-bottom a:hover {
  color: #CF2930;
  background: white;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (max-width: 768px) {
  .buttons-wrapper {
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 100%;
  }

  header {
    padding-bottom: 10rem;
    text-align: center;
    min-height: 130vh;
  }

  header .redline-wrapper {
    margin: 5rem auto 0 0.2rem;
  }

  .header-bottom {
    margin: 0 auto;
  }

  .header-middle {
    margin-top: 100px;
  }

  .header-middle h1, .header-middle p {
    max-width: 100%;
  }

  .header-middle h1 {
    /*font-size: 1.5rem;*/
  }

  .header-bottom a {
    padding: .5em .7em;
  }
}

@media only screen and (min-width: 768px) {
  header {
    padding: 2rem 6rem 6rem 6rem;
    text-align: center;
  }

  .header-top {
    margin-top: -150px;
  }

  .header-middle {
    margin-top: 100px;
    -webkit-align-items: center;
            align-items: center;
  }

  .header-middle p {
    font-size: 1.2rem;
  }

  .header-bottom {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .header-middle h1 {
    /*font-size: 30px;*/
  }

  .header-middle p {
    font-size: 17px;
  }

  header .redline-wrapper {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1050px) {
  .nav-bottom {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    bottom: 0px;
    margin-bottom: -19rem;
    background: url(/static/media/scroll-arrow-to-down-new.4938dbb7.svg) no-repeat;
    background-position: center;
    height: 40px;
    background-position-y: 34px;
  }
}

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 1rem;
  -webkit-transition: background-color 0.5s ease-in;
  transition: background-color 0.5s ease-in;
  z-index: 9999;
  height: 55px;
}

.navbar.active-color {
  background-color: #000;
}

.navbar a {
  font-size: .95rem;
  font-weight: 500;
}

.nav-left {
  -webkit-flex: 3 1;
          flex: 3 1;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.navbar-brand {
  font-family: 'Roboto';
  font-size: 1px;
  letter-spacing: 2px;
}

.navbar-brand h1 {
  margin: 0;
  margin-left: 10px;
  font-size: 18px;
}

.nav-right {
  -webkit-align-items: center;
          align-items: center;
}

.nav-right a {
  padding-top: 8px;
}

.menu {
  display: none;
}

.menu a {
  margin: 0 10px;
}

.menu a:after {
  content: "";
  display: block;
  padding-top: 5px;
  width: 75%;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.15s ease-in;
  transition: border-bottom 0.15s ease-in;
}

.menu a:hover:after {
  /*border-bottom: 2px solid #CF2930;*/
}

nav .dropdown-menu {
  display: block;
}

nav .dropdown-btn {
  margin-top: 22px;
  font-size: 22px;
  cursor: pointer;
  padding-right: 1rem;
}

nav .dropdown-content {
  /* display: none; */
  display: -webkit-flex;
  display: flex;
  /*transform: translateX(-75%);*/
  position: absolute;
  background-color: rgba(0,0,0,0.82);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 0rem;
  padding: 5px 0;
  top: 55px;
  left: 0px;
  color: #fff;
  width: 100%;
  height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

nav .dropdown-content a {
  display: block;
  /*max-width: 9em;*/
  margin: 0 auto;
  text-align: left;
  padding: 12px 10px;
  float: none;
  color: #fff;
  border-bottom: 0px solid #F3F1EE;
  width: 100%;
  font-size: 18px;
  font-family: Bebas Neue Regular, Helvetica, sans-serif, system;
}

nav .dropdown-content a:hover {
  /*background-color: #F3F1EE;*/
}

nav .hidden {
  display: none;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 1050px) {
  .nav-logo {
    position: fixed !important;
    z-index: 10000;
    top: 10px;
    left: 10px;
  }
  .nav-logo.active {
    display: none;
  }
  .navbar {
    /*padding: 0 6rem;*/
    /*bottom: 0px;*/
    /*height: auto;*/
    /*align-items: center;*/
    /*width: 285px;*/
    /*flex-direction: row;*/
    height: 100vh;
    right: auto;
    background-color: transparent !important;
    /*filter: blur(31px);*/
    /*opacity: 0.3;*/
  }

  .navbar .nav-left {
    position: absolute;
    display: none;
  }

  .navbar .nav-right {
    display: none;
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*text-align: left;*/
  }

  .navbar img {
    /*width: 50px;*/
  }

  .dropdown {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .menu {
    display: contents;
  }

  nav .dropdown-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .navbar {
    padding: 0;
  }

  nav .dropdown-content {
    position: relative;
    top: 0px;
    -webkit-justify-content: center;
            justify-content: center;
    box-shadow: none;
    background-color: #ccc;
    opacity: 0.8;
  }

  nav .dropdown-btn {
    -webkit-align-items: center;
            align-items: center;
    padding-left: 8px;
    margin-top: 0px;
  }

  .navbar.active .dropdown-btn {
    background-color: #ccc;
    /*filter: blur(31px);*/
    opacity: 0.8;
  }

  .navbar.active img {
    display: none;
  }

  .navbar.active .dropdown-btn {
    margin-top: 0px;
  }

  nav .dropdown-content a {
    color: #fff;
  }
}

.about {
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.about h2 {
  font-family: pf_din_text_comp_proregular;
}

.about-top {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: left;
          align-items: left;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.goals-wrapper {
  padding-top: 4rem;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.goals-wrapper span {
  color: black;
}

.goals-wrapper h3 {
  /*font-weight: bold;*/
}

.about-bottom .goal {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 30%;
          flex-basis: 30%;
}

.about-bottom h3 {
  /*color: #CF2930;*/
}

.about-bottom p {
  margin-top: 0;
}

.about button {
  width: 70%;
  border-radius: 0;
}

.about p {
  letter-spacing: 1px;
  color: #616161;
}

.buttons-wrapper {
  margin-bottom: 102.98px;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 768px) {
  .about-top .description {
    max-width: 100%;
  }

  .goals-wrapper {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .goals-wrapper .goal {
    -webkit-flex: 0 1 50%;
            flex: 0 1 50%;
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
  }

  .goals-wrapper p {
    max-width: 30rem;
  }

  .about {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    text-align: left;
  }

  .about-top {
    -webkit-flex: 0 1 50%;
            flex: 0 1 50%;
  }

  .image {
    -webkit-flex: 0 1 50%;
            flex: 0 1 50%;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .image img {
    padding-left: 10px;
  }

  .buttons-wrapper {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
}

.details {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.section-title {
  margin: 0;
}

.highlighted {
  /*color: #CF2930;*/
}
/*====================
  Responsive Stylings
=====================*/
.functionalities {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: center;
}

.functionalities .details {
  padding-bottom: 2rem;
}

.functionalities .services {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 50px;
}

.functionalities .service {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 30%;
          flex-basis: 30%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 300px;
  background-color: #171717;
  margin-bottom: 50px;
}

.service-top {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.service-top img {
  width: 80px;
  height: 80px;
}

.service-title {
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
}

.service-title h3,
.service-title .icon {
  margin: 0;
}

.functionalities h3 {
  font-size: 16px;
  color: #7632be;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 768px) {
  .functionalities .services {
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 3rem;
  }

  .functionalities .services {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .functionalities .services .service {
    -webkit-flex: 0 1 30%;
            flex: 0 1 30%;
  }

  .service p {
    padding: 0 19px;
  }

  .functionalities .service-title {
    margin-left: 0px;
    padding-top: 1rem;
  }
}


@media screen and (max-width: 750px){
  .service{
    padding: 30px 0px;
  }
}
.why {
  text-align: center;
}

.why .title-lined:before {
  width: 35%;
}

.reasons {
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 2rem;
}

.why ul {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin: 0;
  list-style: disc outside none;
  list-style: initial;
}

.why ul li {
  -webkit-align-items: left;
          align-items: left;
  text-align: left;
  list-style: none;
  background-position-y: 72px;
  padding: 3px 0px 3px 40px;
  margin: 0 0 20px -10px;
  border-bottom: 1px solid #767676;
  position: relative;
  height: 117px
}

.why ul li p {
  bottom: 20px;
  position: absolute;
  margin: 0px;
}

.why ul p {
  font-size: 1.1rem;
}

.why ul .check {
  margin-right: 1rem;
}

.trust {
  background: url(/static/media/trust.1df1b76d.svg) no-repeat;
}
.ds {
  background: url(/static/media/decentralized-streaming.c80a41ac.svg) no-repeat;
}
.sc {
  background: url(/static/media/smart-contract-functionality.d1dbec32.svg) no-repeat;
}
.tb {
  background: url(/static/media/transparency.a96570e4.svg) no-repeat;
}
.pm {
  background: url(/static/media/payment-method.34d6a69f.svg) no-repeat;
}
.efs {
  background: url(/static/media/encripted.22107ab5.svg) no-repeat;
}

/*======================
    Media Queries
====================*/
@media only screen and (max-width: 768px) {
  .reasons {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .reasons {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .reasons ul {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-flex:1 1 47%;
            flex:1 1 47%;
    -webkit-align-content: baseline;
            align-content: baseline;
  }

  .why ul:nth-child(2) {
    /*margin-left: 10rem;*/
  }

  .why ul p {
    font-size: 1.2rem;
  }

  .why ul li {
    -webkit-flex-basis: 30%;
            flex-basis: 30%;
    -webkit-flex:1 1 50%;
            flex:1 1 50%;
    width: 50%;
  }
}

.team {
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: left;
}

.team .details {
    text-align: center;
}

.team-name {
    text-align: left;
}

.team .description {
    max-width: 30em;
}

.teams .redline-wrapper span {
    margin-right: 10px;
}

.teams .redline-wrapper h4 {
    letter-spacing: 3.5px;
}

/*======================
      Card Stuff
=====================*/
.team-container {
    width: 100%;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.team-row {
    width: 100%;
    height: auto;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    /*justify-content: space-between;*/
}

.team-name {
    width: 100%;
    margin: 3rem 2rem 2rem 0;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.team-name-title {
    /*font-weight: bold;*/
    font-size: 3rem;
    font-family: pf_din_text_comp_proregular;
}

.team-member {
    margin-top: 20px;
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    max-width: 174.61px;
    height: auto;
    -webkit-flex: 1 1 22%;
            flex: 1 1 22%;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-right: 91px;
}

.member-image {
    position: relative;
    max-width: 174.61px;
}

.member-image img {
    opacity: 1;
    max-width: 174.61px;
    height: 225px;
    object-fit: cover;
    -webkit-transition: .25s ease-in;
    transition: .25s ease-in;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.member-image .overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -webkit-transition: .25s ease-in;
    transition: .25s ease-in;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    display: none;
}

.member-image:hover .overlay {
    opacity: 1;
}

.overlay-icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 1.1rem;
    color: white;
    background-color: rgba(300, 300, 300, 0.4);
    padding: 0.6em .75em;
    border-radius: 60px;
    text-align: center;
    -webkit-transition: background-color 0.15s ease-in;
    transition: background-color 0.15s ease-in;
}

.overlay-icon:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.member-body {
    text-align: left;
    max-width: 174.61px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: baseline;
            align-items: baseline;
    width: 100%;
}

.member-body h3 {
    font-size: 1.5rem;
    -webkit-flex: 1 1 80%;
            flex: 1 1 80%;
    margin: 0px;
}

.member-body h5 {
    font-size: 1rem;
    font-weight: 400;
    color: #DC4848;
    text-align: left;
    letter-spacing: 0.5px;
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    margin: 5px 0px;
}

.member-body img {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.member-body a {
    cursor: hand;
}

.detailsBox{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.member-details{
    display: -webkit-flex;
    display: flex;
}

.team .team-name-title {
    color: #767676;
}

/*====================
  Responsive Stylings
=====================*/
@media screen and (max-width: 1022px) {
    .team-member {
        -webkit-flex-basis: 33%;
                flex-basis: 33%;
        padding-right: auto;
    }

    .team-container {

    }
}

@media screen and (max-width: 840px) {
    .team-member {
        -webkit-flex-basis: 50%;
                flex-basis: 50%;
    }
}

@media screen and (max-width: 768px) {
    .team {
        text-align: center;
    }

    .team-member {
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        padding-right: 0;
    }

    .team-name {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        margin: 10px;
    }

    .member-body h5 {
        font-size: .9rem;
        max-width: 13em;
        font-weight: 400;
    }

    .team-row {
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
}

#roadmap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  background: url(/static/media/square-stripes.e6d5b805.svg) no-repeat center;
  background-size: auto;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  height: 400px;
  background-position-y: 0px;
}

.roadmapItemWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.roadmapItem {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background-color: #616161;
  height: 139px;
  width: 373px;
  border-radius: 4px;
  border: 1px solid #fff;
  opacity: 0.2;
}

.roadmapItem:before {
  content: "\25CF";
  font-size: 50px;
  color: #fff;
  position: absolute;
}

.date {
  width: 50%;
  text-align: left;
}
.name {
  width: 50%;
  text-align: right;
  color: #dc4848;
}
.list {
  text-align: left;
}
.list ul {
  list-style-type: circle;
}

.roadmap .description {
  max-width: 28em;
}

.roadmap-img {
  margin: 0 auto;
  content: url(/static/media/desktop-all-with-background-new.6762a32b.svg);
  height: 100%;
  width: 100%;
}
/*====================
  Responsive Stylings
=====================*/
@media only screen and (max-width: 768px) {
  .roadmap-img {
    margin: 0 auto;
    content: url(/static/media/desktop-all-with-background-new.6762a32b.svg);
    /*background: url("../../assets/img/roadmap-mobile-new.jpg");*/
  }
}
@media only screen and (min-width: 768px) {
  .roadmap-img {
    margin: 0 auto;
    content: url(/static/media/desktop-all-with-background-new.6762a32b.svg);
    width: auto;
    /*background: url("../../assets/img/roadmap-mobile-new.jpg");*/
  }
}

.rContainer {
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  margin-bottom: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}

.rBox {
  width: 80%;
}

.rBox::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  background: -webkit-linear-gradient(bottom, #fff 77%, #fafafa 23%);
  background: linear-gradient(0deg, #fff 77%, #fafafa 23%);
  position: absolute;
  left: 50%;
  top: 150px;
  z-index: 0;
}

.cardBox {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 50px;
}

.cardBox2 {
  width: 50%;
  position: relative;
  left: 6.5%;
}

.cardCurrent {
  width: 70%;
  box-sizing: border-box;
  background: #1a1a20;
  border: 1px solid #1f1f23;
  border-radius: 24px;
  padding: 30px;
  -webkit-transition: all 2s;
  transition: all 2s;
  /* background: linear-gradient(
    93.51deg,
    #6639e4 0%,
    #6c2dbf 45.52%,
    #722099 100%
  ); */
  background-color: #65120c;
}

.cardTitle {
  color: white;
  font-size: 22px;
  margin: 0%;
  padding-bottom: 10px;
}

.list {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 17px;
}

.text {
  margin: 0%;
  color: #fefefe;
  font-size: 17px;
}

.text::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 15px;
  background-color: #b2b2c1;
  border-radius: 50%;
  font-size: 13px;
  color: #b2b2c1;
}

.tracker {
  position: absolute;
  left: -90px;
  top: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
}

.trackerBox {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}

.active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
}

.line {
  width: 50px;
  height: 0.2px;
  background-color: #fff;
}

.cardLeft {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-top: 50px;
  padding-left: 4%;
  box-sizing: border-box;
}

.cardLeftBox {
  width: 50%;
  position: relative;
  right: -6.5%;
}

.trackerLeft {
  position: absolute;
  left: 70.5%;
  top: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  z-index: 1;
}

.trackerLeftBox {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
}

.trackerLeftBox span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
}

@media screen and (max-width: 1000px) {
  .cardBox {
    width: 100%;
    margin: 70px 0px;
  }

  .cardBox2 {
    width: 100%;
    left: 0%;
  }

  .cardLeft {
    width: 100%;
    padding-left: 0%;
    margin: 70px 0px;
  }

  .cardLeftBox {
    width: 100%;
    right: 0%;
  }

  .cardCurrent {
    width: 100%;
  }

  .tracker {
    width: 100%;
    top: -50px;
    left: 47.5%;
  }

  .line {
    display: none;
  }

  .trackerLeft {
    width: 100%;
    top: -50px;
    left: 47.5%;
  }

  .rBox::before{
    left: 50.7%;
  }
}

.footer {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: #fff;
}

.footer a:hover {
  color: #CF2930;
}

.footer-top {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-bottom: 0rem;
  /*border-bottom: 1px solid #CF2930;*/
  width: 100%;
}

.footer-box {
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-top: 0rem;
}

.footer-box h2 {
  color: #616161;
  font-size: 12px;
  margin: 0px;
}

.footer-box h4 {
  text-transform: uppercase;
  letter-spacing: 3.5px;
}

.footer-box p {
  font-size: 12px;
  line-height: 1rem;
  color: #616161;
}

.mail h2, .general h2 {
  font-family: pf_din_text_comp_proregular;
  padding-right: 10px;
}

.footer h4 img {
  padding-right: 10px;
}

.contact {
  font-weight: 500;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.contact a {
  font-size: 12px;
}

.footer-nav {
  display: none;
}

.footer-nav ul {
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0;
  margin: 0;
}

.footer-nav li {
  text-transform: uppercase;
}

.footer-nav a {
  font-size: 14px;
  margin: 0 5px;
}

.footer-bottom {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
}

.footer-bottom p {
  font-size: 14px;
}

.footer-bottom .icon {
  font-size: 14px;
  border-radius: 60px;
  padding: .6em .7em;
  background: rgba(149, 152, 154, 0.3);
}

.footer-bottom .icon:hover {
  background-color: white;
}

.footer-bottom a:not(:first-child) {
  margin-left: 5px;
}

.inquires {
  -webkit-flex-direction: column;
          flex-direction: column;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 768px) {
  .footer {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-content: flex-start;
            align-content: flex-start;
  }

  .footer-top {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    width: auto;
  }

  .footer-box {
    padding-top: 0;
  }

  .footer-box.address {
    -webkit-align-items: center;
            align-items: center;
    padding-left: 10px;
  }

  .footer-top {
    /*width: 75%;*/
  }

  .footer-nav li {
    margin: 5px 0;
  }

  .footer-nav a {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-bottom {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /*width: 75%;*/
  }

  .footer-bottom p {
    font-size: 12px;
  }

  .footer-bottom .icon {
    font-size: 14px;
  }

  .footer-bottom-left {
    padding-top: 21px;
  }
}

@media only screen and (min-width: 922px) {
  .footer {
    padding: 6em 6em;
  }
  .footer-bottom .icon {
    font-size: 18px;
  }
}

.partner {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.logoBox{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 100px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 90%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.logoTextBox{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.logoTextBox p{
    font-size: 20px;
    color: #154273;
}

.logo{
    width: 250px;
    height: 245px;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.logo2{
    width: 220px;
    height: 50px;
}
.content {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1;
}

