/*================
  Global Stylings
===================*/
.App {
  overflow-x: hidden;
}

a {text-decoration: none;color: inherit;}

h1 {
  font-family: Bebas Neue Light, Helvetica, sans-serif, system;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
}

h2 {
  font-family: Bebas Neue Regular, Helvetica, sans-serif, system;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h3 {
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0.5px;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

ul {
  list-style: none;
  padding-left: 1.3rem;
}

p {
  font-size: 1rem;
  line-height: 1.875rem;
}

.show {display: block;}

.hide {display: none;}

.dropdown {
  user-select: none;
}

.uppercase {text-transform: uppercase;}

.redline-wrapper {
  align-items: center;
  margin-bottom: 15px;
}

.redline-wrapper span {
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 5px;
}

.has-globe-wallpaper {
  /* background: linear-gradient(0deg,rgba(1, 1, 1, 1),rgba(1, 1, 1, 0)), url('../../assets/img/globe.png') no-repeat; */
  background-size: auto;
  /*height: auto;*/
}

.has-network-wallpaper,
.has-stars-wallpaper {
  background: black;
  color: white;
  border: 2px solid black;
}
/*================
  Button Stylings
===================*/
.btn {
  color: white;
  font-size: 16px;
  border-radius: 50px;
  width: 12rem;
  height: 3.7rem;
  cursor: pointer;
  outline: 0;
  font-family: pf_din_text_comp_promedium;
  letter-spacing: 2px;
}

.btn-primary {
  background-color: #CF2930;
  border: 2px solid #CF2930;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid #CF2930;
}

.btn-secondary:hover {
  background-color: #CF2930;
  border: 2px solid #CF2930;
}

.description {
  font-family: pf_din_text_comp_proregular, Helvetica, sans-serif, system;
  letter-spacing: 1px;
  color: #616161;
  font-size: 1rem;
}

/*====================
  Responsive Stylings
=====================*/
.flex {display: flex;}

@media only screen and (min-width: 520px) {
  .has-globe-wallpaper {
    /*background-attachment: fixed;*/
    background-position: 50%;
  }

  .has-network-wallpaper {
    /*background: linear-gradient(0deg,rgba(1, 1, 1, 0.7),rgba(1, 1, 1, 0)), url('../../assets/img/network.png') no-repeat;*/
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
  }

  .has-stars-wallpaper {
    background: url('../../assets/img/stars.png') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 3rem;
  }

  .has-desktop-padding {
    padding: 6em 6em;
  }
}

@media only screen and (max-width: 768px) {
  .btn {
    width: 10rem;
  }

  .has-mobile-padding {
    padding: 3rem 1rem;
  }
}
