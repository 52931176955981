.why {
  text-align: center;
}

.why .title-lined:before {
  width: 35%;
}

.reasons {
  flex-direction: column;
  padding-top: 2rem;
}

.why ul {
  flex-direction: column;
  width: 100%;
  margin: 0;
  list-style: initial;
}

.why ul li {
  align-items: left;
  text-align: left;
  list-style: none;
  background-position-y: 72px;
  padding: 3px 0px 3px 40px;
  margin: 0 0 20px -10px;
  border-bottom: 1px solid #767676;
  position: relative;
  height: 117px
}

.why ul li p {
  bottom: 20px;
  position: absolute;
  margin: 0px;
}

.why ul p {
  font-size: 1.1rem;
}

.why ul .check {
  margin-right: 1rem;
}

.trust {
  background: url('../../assets/img/trust.svg') no-repeat;
}
.ds {
  background: url('../../assets/img/decentralized-streaming.svg') no-repeat;
}
.sc {
  background: url('../../assets/img/smart-contract-functionality.svg') no-repeat;
}
.tb {
  background: url('../../assets/img/transparency.svg') no-repeat;
}
.pm {
  background: url('../../assets/img/payment-method.svg') no-repeat;
}
.efs {
  background: url('../../assets/img/encripted.svg') no-repeat;
}

/*======================
    Media Queries
====================*/
@media only screen and (max-width: 768px) {
  .reasons {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .reasons {
    flex-direction: row;
  }

  .reasons ul {
    flex-flow: row wrap;
    flex:1 47%;
    align-content: baseline;
  }

  .why ul:nth-child(2) {
    /*margin-left: 10rem;*/
  }

  .why ul p {
    font-size: 1.2rem;
  }

  .why ul li {
    flex-basis: 30%;
    flex:1 50%;
    width: 50%;
  }
}
