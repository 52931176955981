.team {
    flex-direction: column;
    text-align: left;
}

.team .details {
    text-align: center;
}

.team-name {
    text-align: left;
}

.team .description {
    max-width: 30em;
}

.teams .redline-wrapper span {
    margin-right: 10px;
}

.teams .redline-wrapper h4 {
    letter-spacing: 3.5px;
}

/*======================
      Card Stuff
=====================*/
.team-container {
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
}

.team-row {
    width: 100%;
    height: auto;
    flex-flow: row wrap;
    /*justify-content: space-between;*/
}

.team-name {
    width: 100%;
    margin: 3rem 2rem 2rem 0;
    flex-direction: column-reverse;
}

.team-name-title {
    /*font-weight: bold;*/
    font-size: 3rem;
    font-family: pf_din_text_comp_proregular;
}

.team-member {
    margin-top: 20px;
    justify-content: start;
    flex-direction: column;
    text-align: center;
    max-width: 174.61px;
    height: auto;
    flex: 1 22%;
    align-items: flex-start;
    padding-right: 91px;
}

.member-image {
    position: relative;
    max-width: 174.61px;
}

.member-image img {
    opacity: 1;
    max-width: 174.61px;
    height: 225px;
    object-fit: cover;
    transition: .25s ease-in;
    backface-visibility: hidden;
}

.member-image .overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .25s ease-in;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    display: none;
}

.member-image:hover .overlay {
    opacity: 1;
}

.overlay-icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.1rem;
    color: white;
    background-color: rgba(300, 300, 300, 0.4);
    padding: 0.6em .75em;
    border-radius: 60px;
    text-align: center;
    transition: background-color 0.15s ease-in;
}

.overlay-icon:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.member-body {
    text-align: left;
    max-width: 174.61px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
}

.member-body h3 {
    font-size: 1.5rem;
    flex: 1 80%;
    margin: 0px;
}

.member-body h5 {
    font-size: 1rem;
    font-weight: 400;
    color: #DC4848;
    text-align: left;
    letter-spacing: 0.5px;
    flex: 1 100%;
    margin: 5px 0px;
}

.member-body img {
    flex: 1;
}

.member-body a {
    cursor: hand;
}

.detailsBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.member-details{
    display: flex;
}

.team .team-name-title {
    color: #767676;
}

/*====================
  Responsive Stylings
=====================*/
@media screen and (max-width: 1022px) {
    .team-member {
        flex-basis: 33%;
        padding-right: auto;
    }

    .team-container {

    }
}

@media screen and (max-width: 840px) {
    .team-member {
        flex-basis: 50%;
    }
}

@media screen and (max-width: 768px) {
    .team {
        text-align: center;
    }

    .team-member {
        flex-basis: 100%;
        align-items: flex-start;
        padding-right: 0;
    }

    .team-name {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 10px;
    }

    .member-body h5 {
        font-size: .9rem;
        max-width: 13em;
        font-weight: 400;
    }

    .team-row {
        justify-content: space-between;
    }
}
