header {
  color: white;
  padding: 2rem 1rem 6rem 1rem;
  flex-direction: column;
  justify-content: space-between;
  background: url('../../assets/img/martian-wallpaper-group.svg') no-repeat #000;
  background-position: center 40px;
  background-size: auto;
  height: calc(100vh - 110px);
  min-height: 130vh;
}

.nav-bottom {
  display: none;
}

.header-middle {
  flex-direction: column;
  padding-top: 5rem;
}

.header-middle h1 {
  max-width: 17em;
}

.header-middle p {
  max-width: 40rem;
}

.buttons-wrapper {
  align-items: center;
  max-width: 40rem;
  margin-top: 40px;
}

.buttons-wrapper a:nth-child(2) {
  margin-left: 1rem;
}

.buttons-wrapper button {
  align-items: center;
  justify-content: center;
  /* font-family: 'FuturaPT'; */
}

.coming-soon:hover span {
  align-items: center;
  display: none;
}

.coming-soon:hover:before{
  content: "Coming Soon";
}

.buttons-wrapper .icon {
  margin-right: 5px;
}

.header-bottom {
  justify-content: center;
  max-width: 30em;
}

header .redline-wrapper {
  margin: 5rem 0 1rem 0;
}

.header-bottom a {
  border-radius: 100%;
  padding: 1em 1.2em;
  background: rgba(149, 152, 154, 0.3);
}

.header-bottom a:not(:first-child) {
  margin-left: 5px;
}

.header-bottom a:hover {
  color: #CF2930;
  background: white;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (max-width: 768px) {
  .buttons-wrapper {
    justify-content: center;
    max-width: 100%;
  }

  header {
    padding-bottom: 10rem;
    text-align: center;
    min-height: 130vh;
  }

  header .redline-wrapper {
    margin: 5rem auto 0 0.2rem;
  }

  .header-bottom {
    margin: 0 auto;
  }

  .header-middle {
    margin-top: 100px;
  }

  .header-middle h1, .header-middle p {
    max-width: 100%;
  }

  .header-middle h1 {
    /*font-size: 1.5rem;*/
  }

  .header-bottom a {
    padding: .5em .7em;
  }
}

@media only screen and (min-width: 768px) {
  header {
    padding: 2rem 6rem 6rem 6rem;
    text-align: center;
  }

  .header-top {
    margin-top: -150px;
  }

  .header-middle {
    margin-top: 100px;
    align-items: center;
  }

  .header-middle p {
    font-size: 1.2rem;
  }

  .header-bottom {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .header-middle h1 {
    /*font-size: 30px;*/
  }

  .header-middle p {
    font-size: 17px;
  }

  header .redline-wrapper {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1050px) {
  .nav-bottom {
    display: flex;
    position: absolute;
    flex-direction: row;
    align-items: center;
    bottom: 0px;
    margin-bottom: -19rem;
    background: url("../../assets/img/scroll-arrow-to-down-new.svg") no-repeat;
    background-position: center;
    height: 40px;
    background-position-y: 34px;
  }
}
