.about {
  flex-direction: column;
  text-align: center;
}

.about h2 {
  font-family: pf_din_text_comp_proregular;
}

.about-top {
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}

.goals-wrapper {
  padding-top: 4rem;
  flex-direction: column;
  justify-content: space-between;
}

.goals-wrapper span {
  color: black;
}

.goals-wrapper h3 {
  /*font-weight: bold;*/
}

.about-bottom .goal {
  flex-direction: column;
  flex-basis: 30%;
}

.about-bottom h3 {
  /*color: #CF2930;*/
}

.about-bottom p {
  margin-top: 0;
}

.about button {
  width: 70%;
  border-radius: 0;
}

.about p {
  letter-spacing: 1px;
  color: #616161;
}

.buttons-wrapper {
  margin-bottom: 102.98px;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 768px) {
  .about-top .description {
    max-width: 100%;
  }

  .goals-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .goals-wrapper .goal {
    flex: 0 50%;
    flex-basis: 50%;
  }

  .goals-wrapper p {
    max-width: 30rem;
  }

  .about {
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
  }

  .about-top {
    flex: 0 50%;
  }

  .image {
    flex: 0 50%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .image img {
    padding-left: 10px;
  }

  .buttons-wrapper {
    justify-content: flex-start;
  }
}
