.functionalities {
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.functionalities .details {
  padding-bottom: 2rem;
}

.functionalities .services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 50px;
}

.functionalities .service {
  flex-direction: column;
  flex-basis: 30%;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-color: #171717;
  margin-bottom: 50px;
}

.service-top {
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.service-top img {
  width: 80px;
  height: 80px;
}

.service-title {
  flex-direction: column;
  margin-left: 20px;
}

.service-title h3,
.service-title .icon {
  margin: 0;
}

.functionalities h3 {
  font-size: 16px;
  color: #7632be;
}

/*====================
  Responsive Stylings
=====================*/
@media only screen and (min-width: 768px) {
  .functionalities .services {
    flex-direction: row;
    margin-top: 3rem;
  }

  .functionalities .services {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .functionalities .services .service {
    flex: 0 30%;
  }

  .service p {
    padding: 0 19px;
  }

  .functionalities .service-title {
    margin-left: 0px;
    padding-top: 1rem;
  }
}


@media screen and (max-width: 750px){
  .service{
    padding: 30px 0px;
  }
}